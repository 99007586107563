import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Pipe } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './services/api.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { SessionFlow } from '../helper/session-flow';
import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CookieService } from './services/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryPincodeService {
  pincodeSub = new BehaviorSubject<string>(null);
  pincodeObs$ = this.pincodeSub.asObservable();
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    protected sessionFlow: SessionFlow,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformID: object
  ) { 
   
  }

  storePincode(pincode = null){
    if(isPlatformBrowser(this.platformID)){
      localStorage.setItem('delivery_pincode', pincode);      
      const deviceId = this.sessionFlow.deviceId;
      const params = new HttpParams({
        fromObject: {
          device_id: deviceId,
          pincode: pincode,
        }
      });
      
      this.apiService.getHttpWithParams<any>(`v1/update-pincode`, params).pipe(
        map(res => res.body),
        tap(res => {
          if(res.token){
            if(sessionStorage.getItem('session_token') === null){
              sessionStorage.setItem('session_token', res.token);
            }
          }
          if(res.pincode){
            localStorage.setItem('delivery_pincode', pincode);
            this.pincodeSub.next(pincode);
          }
        })
      ).subscribe();
    }
  }
  fetchPincode(): Observable<any>{
    const deviceId = this.sessionFlow.deviceId;
      const params = new HttpParams({
        fromObject: {
          device_id: deviceId,
          token: sessionStorage.getItem('session_token')
        }
      });
    return this.apiService.getHttpWithParams<any>(`v1/get-pincode`, params).pipe(
      map(res => res),
      tap(res =>  {
        if(res.token){
          this.pincodeSub.next(res.token);
        }
      })
    )
  }
  getPincode(): Observable<any> {
    
    return new Observable((observer) => {
      const pincode = isPlatformBrowser(this.platformID) ? localStorage.getItem('delivery_pincode') : null
      if(pincode !== null){
        this.pincodeSub.next(pincode);     
        observer.next(pincode);
        observer.complete();
      }
      
    })
    
  }
  
}
